import React from "react";

import "./FeaturesCarousel.css";

const FeaturesCarousel = () => {
  return (
    <></>
  )
};

export default FeaturesCarousel;