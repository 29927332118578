import React from "react";

import "./ThreadPreview.css";

const ThreadPreview = () => {
  return (
    <div className="thread-preview"></div>
  );
};

export default ThreadPreview;